<template>
    <div>
        <le-pagview ref="eventlist" @setData="setTableData" :pageParam="pageParam">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="false">
                <le-date-range ref="eventdate" :minDate.sync="startTime" :maxDate.sync="endTime" :clearable='false' valueFormat="yyyy-MM-dd HH:mm:ss" :defaultTime="['00:00:00','23:59:59']"/>
                <jd-select-input label="卡号" :maxlength="10" :value.sync="searchVal.value" :selectValue.sync="searchVal.select" :selectOptions="selectOptions"></jd-select-input>
                <le-input label="处理人" v-model="pageParam.params.phone" />
            </le-search-form>
            <el-table :data="tabledata">
                <el-table-column label="事件类型" prop="typeText"></el-table-column>
                <el-table-column label="详细说明" prop="content"></el-table-column>
                <el-table-column label="订单ID" prop="orderId">
                    <template slot-scope="{ row }">
                        <span>{{ row.orderId?row.orderId:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="处理人" prop="operatorUserPhone">
                    <template slot-scope="{ row }">
                        <span>{{ row.operatorUserPhone?row.operatorUserPhone:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="卡号" prop="cardNo">
                    <template slot-scope="{ row }">
                        <span>{{ row.cardNo?row.cardNo:'-' }}（反序卡号：{{ row.cardNo?util.ox16Reverse(row.cardNo):'-' }}）</span>
                    </template>
                </el-table-column>
                <el-table-column label="处理时间" prop="createTimeText"></el-table-column>
            </el-table>
        </le-pagview>
    </div>
</template>

<script>
    import util from '../../../../src/utils/util'
    export default {
        data() {
            return {
                util: util,
                pageParam:{
                    url: this.$Config.apiUrl.getDeviceEvent,
                    method: "post",
                    params: {
                        deviceCode: this.deviceCode,// 设备码
                        cardNo: '',
                        phone: '',
                        startTime: this.$getDay.getTodayBeforeDays(6) + ' 00:00:00',
                        endTime: this.$getDay.getToday() + ' 23:59:59'
                    },
                    freshCtrl: 1,
                },
                tabledata: [],
                startTime: this.$getDay.getTodayBeforeDays(6) + ' 00:00:00',
                endTime: this.$getDay.getToday() + ' 23:59:59',
                searchVal: {
                    value: '',
                    select: '1'
                },
                selectOptions: [{
                    label: '正序',
                    value: '1'
                },{
                    label: '反序',
                    value: '2'
                }]
            }
        },
        props:['deviceCode'],
        created () {
        },
        watch:{
            startTime(newval,oldval) {
                this.pageParam.params.startTime = newval
                // this.pageParam.freshCtrl++
            },
            endTime(newval,oldval) {
                this.pageParam.params.endTime = newval
                // this.pageParam.freshCtrl++
            },
        },
        methods:{
            handlerRest() {
                this.pageParam.params = {
                    deviceCode: this.deviceCode,// 设备码
                    startTime: this.$getDay.getTodayBeforeDays(6) + ' 00:00:00',
                    endTime: this.$getDay.getToday() + ' 23:59:59',
                    cardNo: '',
                    phone: '',
                };
                this.searchVal = {
                    value: '',
                    select: '1'
                }
                // this.$set(this.pageParam.params,'startTime',this.$getDay.getTodayBeforeDays(6))
                // this.$set(this.pageParam.params,'endTime',this.$getDay.getToday())
                // this.$refs['eventdate'].value1 = ['','']
                this.$refs['eventdate'].value1 = [this.$getDay.getTodayBeforeDays(6) + ' 00:00:00', this.$getDay.getToday() + ' 23:59:59']
                // this.$refs['onlinelist'].clearFilter()
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs.eventlist.pageNum = 1
                if(this.searchVal.select == 2) {
                    this.pageParam.params.cardNo = this.searchVal.value?util.ox16Reverse(this.searchVal.value):''
                }else{
                    this.pageParam.params.cardNo = this.searchVal.value
                }
                this.pageParam.freshCtrl++;
            },
            setTableData (datas) {
                this.tabledata = datas
            }
        }
    }
</script>