<template>
    <div>
        <el-dialog
            title="声光报警设置"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="1000px">
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                <el-form-item label="设置类型" prop="type">
                    <div class="a-flex-rfsc">
                        <el-radio-group v-model="form.type">
                            <el-radio 
                                v-for="(item,index) in statusDic"
                                :key="index"
                                :label="item.value" >{{ item.label }}</el-radio>
                        </el-radio-group>
                        <jd-input-intnum class="a-ml-12" v-model="form.time" :maxlength="6" placeholder="请输入开启时长，秒"></jd-input-intnum>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            var checkType = (rule, value, callback) => {
                if(this.form.type == 3 && !this.form.time){
                    callback(new Error('请输入开启时长'))
                }else {
                    callback()
                }
            };
            return {
                dialogVisible: false,
                form: {
					type: '1',
					time: '',
                },
                rules: {
                    type: [{ validator: checkType, trigger: 'change' },{required: true, message:'请选择设置类型', trigger: 'change'}],
                },
                statusDic: [
                    {
                        label: '开启',
                        value: '1'
                    },
                    {
                        label: '关闭',
                        value: '2'
                    },
                    {
                        label: '开启固定时长',
                        value: '3'
                    }
                ]
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
                if(!val){
                    this.form = {
                        type: '1',
					    time: '',
                    }
                }
            },
        },
        props: {
            deviceId:{
                default: ''
            },
        },
        methods:{
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$confirm('是否确认提交？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            this.$Axios._post({
                                url: this.$Config.apiUrl.smokeAcoustoOptic,
                                params: {
                                    id: this.deviceId,
									status: this.form.type == 1 || this.form.type == 3 ? 1 : 0,
									time: this.form.type == 3 ? this.form.time : ''
                                },
                            })
                            .then(res=>{
                                if (res.result.code == 0) {
                                    this.$message.success('操作成功')
                                    this.dialogVisible = false
                                } else {
                                    this.$message.error(res.result.message)
                                }
                            })
                            .catch(err=>{

                            })
                        }).catch(_=>{ })
                    }
                })
                
            },
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        /*width: 0;宽度为0隐藏*/
        // width: 10px !important;
        height: 10px !important;
    }
    .line{
        font-size: 14px;
        font-weight: 700;
        color: #333;
        // border-top: 1px dashed #C0C4CC;
        // padding-top: 20px;
    }
</style>