<template>
    <div class="app-body">
        <div class="a-flex-rsbc">
            <bread-crumb></bread-crumb>
            <div class="a-flex-rcc">
                <el-button class="s-btn-danger a-mlr-12" icon="el-icon-unbind" v-if="deviceInfo && deviceInfo.stationName" @click="unbindDev">解绑</el-button>
                <el-button 
                    class="s-btn-danger" 
                    style="margin-right: 12px;margin-left: 0;" 
                    icon="el-icon-close" 
                    v-if="deviceInfo&&(deviceInfo.deviceCategroy == 1 || deviceInfo.deviceCategroy == 4 || deviceInfo.deviceCategroy == 5)" @click="stopCharge">停止充电</el-button>
                <el-dropdown placement="bottom-start" @command="handlerDev" v-if="deviceInfo">
                    <el-button class="s-btn-exp" icon="el-icon-more">更多</el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item 
                            v-for="item in optionsMore" 
                            :command='item.value' 
                            :key="item.value" 
                            :disabled="(deviceInfo.onlineStatus==0 && (item.value==2 || item.value==1))
                             || (deviceInfo.deviceCategroy != 1 && item.value == 4)"
                            :value="item.value">{{ item.label }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="devInfo">
            <div class="">
                <el-card class="dev-info-box">
                    <div class="a-flex-rfsc">
                        <span class="a-fs-16 a-fw-500 a-c-master">NO.{{ deviceInfo&&deviceInfo.deviceCode?deviceInfo.deviceCode:'' }}</span>
                        <div :class="deviceInfo&&deviceInfo.onlineStatus===1?'deviceStatus':'deviceStatusOff'" class="a-fs-14 a-flex-rcc">{{ (deviceInfo?deviceInfo.onlineStatus:'') | initOnlineStatus }}</div>
                    </div>
                    <div class="a-flex-rfsc a-flex-wrap a-mt-16">
                        <div class="a-flex-rfsfs a-mt-16">
                            <span class="dev-info-title a-fs-14 a-c-normal">序列号</span>
                            <span class="dev-info-content a-fs-14 a-c-master">{{ deviceInfo&&deviceInfo.serialNumber?deviceInfo.serialNumber:'-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs a-mt-16">
                            <span class="dev-info-title a-fs-14 a-c-normal">设备ID</span>
                            <span class="dev-info-content a-fs-14 a-c-master">{{ deviceInfo&&deviceInfo.deviceId?deviceInfo.deviceId:'-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs a-mt-16">
                            <span class="dev-info-title a-fs-14 a-c-normal">IMEI</span>
                            <span class="dev-info-content a-fs-14 a-c-master">{{ deviceInfo&&deviceInfo.imei?deviceInfo.imei:'-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs a-mt-16">
                            <span class="dev-info-title a-fs-14 a-c-normal">ICCID</span>
                            <span class="dev-info-content a-fs-14 a-c-master">{{ deviceInfo&&deviceInfo.iccid?deviceInfo.iccid:'-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs a-mt-16">
                            <span class="dev-info-title a-fs-14 a-c-normal">设备厂商</span>
                            <span class="dev-info-content a-fs-14 a-c-master">{{ deviceInfo&&deviceInfo.deviceFactory?deviceInfo.deviceFactory:'-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs a-mt-16">
                            <span class="dev-info-title a-fs-14 a-c-normal">设备种类</span>
                            <span class="dev-info-content a-fs-14 a-c-master">{{ deviceInfo&&deviceInfo.deviceCategroyText?deviceInfo.deviceCategroyText:'-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs a-mt-16">
                            <span class="dev-info-title a-fs-14 a-c-normal">设备类型</span>
                            <span class="dev-info-content a-fs-14 a-c-master">{{ deviceInfo&&deviceInfo.deviceTypeText?deviceInfo.deviceTypeText:'-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs a-mt-16">
                            <span class="dev-info-title a-fs-14 a-c-normal">插槽数</span>
                            <span class="dev-info-content a-fs-14 a-c-master">{{ deviceInfo&&deviceInfo.slotNum?deviceInfo.slotNum:'-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs a-mt-16">
                            <span class="dev-info-title a-fs-14 a-c-normal">信号强度</span>
                            <span class="dev-info-content a-fs-14 a-c-master" :style="csqStyle">{{ (deviceInfo?deviceInfo.csq:'') | initcsq }}</span>
                        </div>
                        <div class="a-flex-rfsfs a-mt-16">
                            <span class="dev-info-title a-fs-14 a-c-normal">所属商户</span>
                            <span v-if="deviceInfo&&deviceInfo.companyName&&$_has(35)" class="dev-info-content a-fs-14 a-c-blue font-point" @click="toFranchiseeDetail">{{ deviceInfo.companyName }}</span>
                            <span v-else class="dev-info-content a-fs-14 a-c-master">-</span>
                        </div>
                        <div class="a-flex-rfsfs a-mt-16">
                            <span class="dev-info-title a-fs-14 a-c-normal">当前站点</span>
                            <le-jumpto-detail 
                                v-if="deviceInfo&&deviceInfo.stationName&&$_has(6)"
                                class="dev-info-content a-fs-14 a-c-blue font-point" 
                                url="/station/station-detail" 
                                :dQuery="{ 'stationId': deviceInfo.stationId }">
                                <span>{{ deviceInfo.stationName }}</span>
                            </le-jumpto-detail>
                            <span v-else class="dev-info-content a-fs-14 a-c-master">-</span>
                        </div>
                        <div class="a-flex-rfsfs a-mt-16">
                            <span class="dev-info-title a-fs-14 a-c-normal">是否启用</span>
                            <span class="dev-info-content a-flex-rfsc a-fs-14 a-c-master">
                                <!-- <el-switch
                                    :value="(deviceInfo&&deviceInfo.canUse) || false"
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-color="#007AFF"
                                    inactive-color="#F56C6C">
                                </el-switch> -->
                                <span class="a-c-normal">{{ deviceInfo&&deviceInfo.canUse==1?'已启用':'已禁用' }}</span>
                            </span>
                        </div>
                        <div class="a-flex-rfsfs a-mt-16">
                            <span class="dev-info-title a-fs-14 a-c-normal">软件版本</span>
                            <span class="dev-info-content a-fs-14 a-c-master">{{ deviceInfo&&deviceInfo.softVersion?deviceInfo.softVersion:'-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs a-mt-16">
                            <span class="dev-info-title a-fs-14 a-c-normal">硬件版本</span>
                            <span class="dev-info-content a-fs-14 a-c-master">{{ deviceInfo&&deviceInfo.hardwareVersion?deviceInfo.hardwareVersion:'-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs a-mt-16">
                            <span class="dev-info-title a-fs-14 a-c-normal">入库时间</span>
                            <span class="dev-info-content a-fs-14 a-c-master">{{ deviceInfo&&deviceInfo.createTimeText?deviceInfo.createTimeText:'-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs a-mt-16">
                            <span class="dev-info-title a-fs-14 a-c-normal">安装时间</span>
                            <span class="dev-info-content a-fs-14 a-c-master">{{ deviceInfo&&deviceInfo.installTimeText?deviceInfo.installTimeText:'-' }}</span>
                        </div>
                    </div>
                    <div class="a-flex-rfsfs a-mt-16" v-if="deviceInfo && (deviceInfo.deviceCategroy == 1 || deviceInfo.deviceCategroy == 3 || deviceInfo.deviceCategroy == 4 || deviceInfo.deviceCategroy == 5)">
                        <span class="dev-info-title a-fs-14 a-c-normal" style="min-width: 100px">插槽使用情况</span>
                        <div class="a-flex-rfsfs a-flex-wrap">
                            <div class="a-flex-ccc slotUseInfo" 
                                :class="!val||val<0 ? deviceInfo.selectSlotNo==key?'chooseSlot':'slotUseInfo_inac' : 'slotUseInfo_ac'" 
                                v-for="(val,key,index) in deviceInfo.slotUseStatus" 
                                :key="index"
                                @click="chooseSlotNo(key,val)">
                                <span class="a-fs-14">{{ key }}</span>
                                <span class="a-fs-12" :style="!val||val<0 ? '' : 'color:#f3a007'">{{ !val||val<0 ? '空闲' : '使用中' }}</span>
                            </div>
                        </div>
                    </div>
                    <el-tabs class="a-mt-16 devicetabs" v-model="tabs" @tab-click="handleTabsClick">
                        <!-- <el-tab-pane label="实时信息" name="0"></el-tab-pane> -->
                        <el-tab-pane label="绑定/解绑记录" name="1"></el-tab-pane>
                        <!-- <el-tab-pane label="7天流水日志" name="2"></el-tab-pane> -->
                        <el-tab-pane label="事件记录" name="3"></el-tab-pane>
                        <el-tab-pane label="在线记录" name="4"></el-tab-pane>
                        <el-tab-pane label="绑定权限" name="5" v-if="deviceInfo&&deviceInfo.deviceCategroy == 2"></el-tab-pane>
                    </el-tabs>
                </el-card>
                <div class="dev-info-content-card a-mt-16">
                    <dev-realtime-info style="padding-top:24px" :deviceCode="deviceCode" v-if="tabs == '0'"></dev-realtime-info>
                    <dev-bind-list style="padding-top:8px" :deviceCode="deviceCode" v-if="tabs == '1'"></dev-bind-list>
                    <dev-log :deviceCode="deviceCode" v-if="tabs == '2'"></dev-log>
                    <dev-event-list :deviceCode="deviceCode" v-if="tabs == '3'"></dev-event-list>
                    <dev-online-list :deviceCode="deviceCode" v-if="tabs == '4'"></dev-online-list>
                    <dev-bind-auth :deviceCode="deviceCode" :deviceType="deviceType" v-if="tabs == '5'"></dev-bind-auth>
                </div>
            </div>
            <dev-param-set ref="paramSet" @paramsSet="paramsSetResult"></dev-param-set>
            <dev-param-result ref="paramResult" @resetParams="resetParams"></dev-param-result>
            <dev-reboot-result ref="rebootResult" :tableData="rebootResult"></dev-reboot-result>
        </div>
        <a-poppup ref="aPoppup">
            <div class="pop-content a-flex-rcc">
                <!-- <el-progress class="progress" :stroke-width="8" :percentage="percentage" color="#007AFF" :show-text="false"></el-progress> -->
                <div class="progress">
                    <div class="progress-item"></div>
                </div>
            </div>
        </a-poppup>
        <remote-charge ref="remoteCharge" :deviceInfo="deviceInfo"></remote-charge>
        <remote-cabinet-charge ref="remoteCabinetCharge" :deviceInfo="deviceInfo"></remote-cabinet-charge>
        <dev-stop-charge ref="devStopCharge" :deviceInfo="deviceInfo"></dev-stop-charge>
        <dev-sense ref="devSense" :deviceInfo='deviceInfo'></dev-sense>
        <dev-acoustoptic-set ref="devAcoustoPticSet" :deviceId='deviceInfo.deviceId' v-if="deviceInfo"></dev-acoustoptic-set>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import DevBindList from './child/dev-bind-list.vue'
import DevEventList from './child/dev-event-list.vue'
import DevLog from './child/dev-log.vue'
import DevOnlineList from './child/dev-online-list.vue'
import devRealtimeInfo from './child/dev-realtime-info.vue'
import APoppup from '../components/poppup/a-poppup.vue'
import DevRebootResult from './child/dev-reboot-result.vue';
import DevParamSet from './child/dev-paramSet.vue';
import DevParamResult from './child/dev-param-result.vue';
import RemoteCharge from './child/remoteCharge.vue'
import devBindAuth from './child/dev-bind-auth.vue'
import RemoteCabinetCharge from './child/remote-cabinet-charge.vue'
import DevStopCharge from './child/dev-stop-charge.vue';
import DevSense from './child/dev-sense.vue';
import DevAcoustopticSet from './child/dev-acoustoptic-set.vue';

export default {
    components: { 
        devRealtimeInfo, 
        DevBindList, 
        DevLog, 
        DevEventList, 
        DevOnlineList, 
        APoppup, 
        DevRebootResult, 
        DevParamSet, 
        DevParamResult, 
        RemoteCharge, 
        devBindAuth,
        RemoteCabinetCharge,
        DevStopCharge,
        DevSense,
        DevAcoustopticSet,
    },
    data() {
        return {
            deviceInfo: null,
            optionsMore:[{
                label: "远程重启",
                value: 1,
            }, {
                label: "参数下发",
                value: 2,
            }, {
                label: "编辑",
                value: 3,
            }, {
                label: "远程开电",
                value: 4,
            }, {
                label: "远程开门开电",
                value: 5,
            }, {
                label: "设备检测",
                value: 6,
            }, {
                label: "设备升级",
                value: 7,
            }, {
                label: "设置音量",
                value: 8,
            }, {
                label: "声光报警设置",
                value: 9,
            }],
            deviceCode: '',//设备code
            deviceType: '',
            tabs: '1',
            rebootResult: [],
            maxVolume: 30,
        }
    },
    created () {
        this.deviceCode = this.$route.query.deviceCode
        
    },
    mounted () {
        if(this.deviceCode) this.getDeviceInfo()
    },
    filters:{
        initcsq (val) {
            if(val && val >= 18){
                return '强(-1db)'
            }else if(val && val >= 11 && val < 18){
                return '中(-50db)'
            }else if(val && val < 11){
                return '弱(-98db)'
            }else{
                return '无信号'
            }
        },
        initOnlineStatus (val) {
            if(val === 1){
                return '在线'
            }else if(val === 2){
                return '故障'
            }else{
                return '离线'
            }
        }
    },
    computed:{
        csqStyle () {
            let val = this.deviceInfo && this.deviceInfo.csq ? this.deviceInfo.csq : ''
            if(val && val >= 18){
                return 'color: #29CC29'
            }else if(val && val >= 11 && val < 18){
                return 'color: #FFA900'
            }else if(val && val < 11){
                return 'color: #FF3B30'
            }else{
                return 'color: #606366'
            }
        },
        ...mapState({
            company: state => state.company.company,
            userInfo: state => state.user.user,
        })
    },
    methods:{
        // 获取设备信息
        getDeviceInfo () {
            this.$Axios._get({
                url: this.$Config.apiUrl.getDeviceBaseInfo,
                method: "get",
                params: {
                    deviceCode : this.deviceCode,
                }
            }).then(res => {
                if(res.result.code == 0){
                    this.deviceInfo = res.result.data
                    this.deviceType = res.result.data.deviceType
                    this.maxVolume = res.result.data.maxVolume || 30 //设备音量最大值，默认30
                }else{
                    this.$message.error(res.result.message)
                }
            })
        },
        // 切换选项卡
        handleTabsClick (tab) {
            this.tabs = tab.name
        },
        // 设备解绑
        unbindDev () {
            this.$confirm('是否确认解除绑定?', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(_=>{
                this.$Axios._post({
                    url: this.$Config.apiUrl.batchUnBindDevice,
                    method: "post",
                    params: {
                        deviceCodes : [this.deviceCode]
                    }
                }).then(res => {
                    if(res.result.code == 0){
                        this.$message.success('操作成功')
                        this.getDeviceInfo()
                        this.tabs = '0'
                    }else{
                        this.$message.error(res.result.message)
                    }
                })
            }).catch(_=>{})
        },
        handlerDev (command) {
            switch (command) {
                case 1:
                    // 重启
                    this.$confirm('是否确认远程重启所选设备?', '温馨提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(_=>{
                        this.batchRebootDev([this.deviceCode])
                    }).catch(_=>{ })
                    break;
                case 2:
                    // 参数下发
                    let arr = [{
                        deviceCode: this.deviceCode,
                        deviceType: this.deviceInfo.deviceType
                    }]
                    this.devParamSet(arr)
                    break;
                case 3:
                    // 编辑
                    this.$router.push({
                        path:'/device/device-edit',
                        query: {
                            deviceCode: this.deviceCode
                        }
                    })
                    break;
                case 4:
                    // 远程开电
                    this.$refs['remoteCharge'].dialogVisible = true
                    break;
                case 5:
                    if(this.deviceInfo.deviceCategroy != 4 && this.deviceInfo.deviceCategroy != 5){
                        this.$message.warning('该设备种类不支持远程开门开电操作')
                        return
                    }
                    this.$refs['remoteCabinetCharge'].dialogVisible = true
                    break;
                case 6:
                    if(this.deviceInfo.deviceCategroy != 1 && this.deviceInfo.deviceCategroy != 4 && this.deviceInfo.deviceCategroy != 5){
                        this.$message.warning('该设备种类不支持设备检测')
                        return
                    }
                    this.devsense()
                    break
                case 7:
                    this.devUpdate()
                    break
                case 8:
                    if(!this.deviceInfo.volume) {
                        this.$message.warning('该设备版本不支持音量设置，请先升级设备软件版本')
                        return
                    }
                    this.devSetVolume()
                    break
                case 9:
                    if(this.deviceInfo.deviceType != 17) {
                        this.$message.warning('该设备类型不支持声光报警设置')
                        return
                    }
                    this.devSetAcoustoOptic()
                    break
                default:
                    break;
            }
        },
        // 远程重启
        batchRebootDev (datas) {
            this.showPop()
            this.$Axios._post({
                url: this.$Config.apiUrl.batchReboot,
                method: "post",
                noShowLoading: true,
                params: {
                    deviceCodes : datas
                }
            }).then(res => {
                this.$refs['aPoppup'].close()
                if(res.result.code == 0){
                    this.rebootResult = res.result.data
                    this.$refs['rebootResult'].dialogVisible = true
                    this.getDeviceInfo()
                }else{
                    this.$message.error(res.result.message)
                }
            }).catch(()=>{
                this.$refs['aPoppup'].close()
            })
        },
        devsense () {
            if(this.deviceInfo.deviceCategroy == 4 || this.deviceInfo.deviceCategroy == 5) {
                this.$Axios._get({
                    url: this.$Config.apiUrl.deviceSense,
                    method: "get",
                    params: {
                        deviceCode: this.deviceCode
                    }
                }).then(res => {
                    if(res.result.code == 0){
                        let arr = res.result.data.map(item=>{
                            return {
                                ...item,
                                current: item.rmsCurrent,
								power: item.activePower,
                            }
                        })
                        this.$refs['devSense'].tableData = arr
                        this.$refs['devSense'].dialogVisible = true
                    }else{
                        this.$message.error('设备检测失败')
                    }
                }).catch(err=>{
                    this.$message.error('设备检测失败')
                })
            }else {
                this.$Axios._get({
                    url: this.$Config.apiUrl.devsense,
                    method: "get",
                    params: {
                        imei: this.deviceInfo.imei
                    }
                }).then(res => {
                    if(res.result.code == 0){
                        let arr = []
                        for (const key in res.result.data.slotStatus) {
                            if (Object.hasOwnProperty.call(res.result.data.slotStatus, key)) {
                                const element = res.result.data.slotStatus[key]
                                arr.push(element)
                            }
                        }
                        this.$refs['devSense'].tableData = arr
                        this.$refs['devSense'].dialogVisible = true
                    }else{
                        this.$message.error('设备检测失败')
                    }
                }).catch(err=>{
                    this.$message.error('设备检测失败')
                })
            }
            
        },
        // 设备升级
        devUpdate () {
            this.$Axios._get({
                url: this.$Config.apiUrl.deviceUpdateNormal,
                method: "get",
                params: {
                    imei:this.deviceInfo.imei,
					deviceType: this.deviceInfo.deviceType
                }
            }).then(res => {
                if(res.result.code == 0){
                    this.$alert('操作成功，设备升级中...', '温馨提示', {
                        confirmButtonText: '确定',
                    });
                }else{
                    this.$message.error('设备升级失败')
                }
            }).catch(err=>{
                this.$message.error('设备升级失败')
            })
        },
        // 设备设置音量
        devSetVolume () {
            this.$prompt('请输入音量（音量范围0-100）', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^(0|[1-9]\d?|100)$/,
                inputErrorMessage: '请输入0-100的整数'
            }).then(({ value }) => {
                this.$Axios._post({
                    url: this.$Config.apiUrl.deviceSetvolume,
                    method: "post",
                    params: {
                        imei:this.deviceInfo.imei,
                        volume: parseInt(value / 100 * this.maxVolume)
                    }
                }).then(res => {
                    if(res.result.code == 0){
                        this.$message.success('操作成功')
                    }else{
                        this.$message.error(res.result.message)
                    }
                })
            }).catch(() => {
        
            });
            
        },
        devSetAcoustoOptic () {
            this.$refs['devAcoustoPticSet'].dialogVisible = true
        },
        // 参数下发
        devParamSet (datas,type='single') {
            this.$refs.paramSet.devDatas = datas
            this.$refs.paramSet.setType = type
            this.$refs.paramSet.deviceId = this.deviceInfo.deviceId
            this.$refs.paramSet.dialogVisible = true
        },
        paramsSetResult (datas) {
            this.$refs.paramResult.tableData = datas
            this.$refs.paramResult.dialogVisible = true
        },
        // 下发参数重试
        resetParams (datas) {
            this.devParamSet(datas,'reset')
        },
        // 显示弹窗
        showPop () {
            this.$refs['aPoppup'].open({
                title: '设备重启中',
                subTitle: '预计等待时间1分30秒',
                showConfirm: true,
                showCancel: false,
                confirmText: '确认',
                cancelText: '取消'
            },(res)=>{
                console.log(res)
            })
        },
        //跳转商户详情
		toFranchiseeDetail (datas) {
			if(this.deviceInfo.status==2){
				this.$router.push({
					path: '/franchisee/franchisee-contract?code='+this.deviceInfo.companyId
				})
			}else if(this.deviceInfo.status==3){
				this.$router.push({
					path: '/franchisee/franchisee-change?code='+this.deviceInfo.companyId
				})
			}else{
				this.$router.push({
					path: '/franchisee/franchisee-detail?code='+this.deviceInfo.companyId
				})
			}
			
		},
        chooseSlotNo (datas, val) {
            this.$set(this.deviceInfo,'selectSlotNo',!val||val<0?datas:this.deviceInfo.selectSlotNo)
        },
        stopCharge () {
            this.$refs['devStopCharge'].dialogVisible = true
        }
    }
}
</script>

<style lang="scss" scoped>
.devInfo{
    height: 100%;
    overflow-y: auto;
    background: #ffffff;
    font-size: 14px;
}
.deviceStatus{
    width: 58px;
    height: 24px;
    background: #E6FFE6;
    border-radius: 4px;
    margin-left: 8px;
    color: #29CC29;
}
.deviceStatusOff{
    width: 58px;
    height: 24px;
    background: rgba($color: #FF3B30, $alpha: 0.2);
    border-radius: 4px;
    margin-left: 8px;
    color: #FF3B30;
}
.dev-info-title{
    width: 80px;
}
.dev-info-content{
    width: 380px;
    margin-right: 37px;
}
.width80{
    width: 80px;
}
.width280{
    width: 280px;
}
/deep/ .date-range{
    margin-left: 0 !important;
}
.dev-info-content-card{
    background: #FFFFFF;
    border-radius: 4px;
    padding: 0 24px 24px;
}
.dev-info-box{
    padding: 4px !important;
}

/deep/ .el-icon-unbind{
    background: url('../../assets/icon/unbind.png') center no-repeat;
    background-size: 100% 100%;
    width: 12px;
    height: 12px;
    font-size: 12px;
}
/deep/ .el-icon-unbind:before{
    content: "替";
    font-size: 12px;
    visibility: hidden;
}
/deep/ .el-tabs__nav-wrap::after{
    background-color: transparent
}
.devicetabs{
    margin-bottom: -36px
}
.progress{
    width: 205px;
    height: 7px;
    border-radius: 4px;
    margin: 13px 0 24px;
    background: #B9B9B9
}
.progress-item{
    width: 100%;
    height: 7px;
    border-radius: 4px;
    background: #007AFF;
    animation: progressPop 10s;
    -webkit-animation: progressPop 5s; /* Safari and Chrome */
}
/* 动画 */
@keyframes progressPop
{
    from {width: 0;}
    to {width:100%;}
}

@-webkit-keyframes progressPop /* Safari and Chrome */
{
    from {width: 0;}
    to {width:100%;}
}
/deep/ .el-tabs__item {
    // color: #007aff;
    font-size: 14px;
}

/deep/ .el-tabs__item.is-active {
    color: #007AFF;
    font-weight: bold;
}

/deep/ .el-tabs__nav-wrap::after {
    background: none !important;
}
.slotUseInfo{
    width: 54px;
    height: 46px;
    border-radius: 3px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
}
.slotUseInfo_ac{
    color: #333;
	background: #f5f5f5;
}
.slotUseInfo_inac{
    background: #e6fbfa;
	color: #11c4bf;
}
.chooseSlot{
    background: #11c4bf;
    color: #fff;
}
</style>